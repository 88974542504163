<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h4 class="mb-3">{{ $t('company.person.new_person') }}</h4>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-form__element">
                <label class="form-label">{{ company.name }}</label>
              </div>

              <div class="mx-modal-form__element" v-for="element in form_elements">
                <label class="form-label">{{ $t(`company.person.form.${element}`) }}</label>
                <input type="text" class="form-control" v-model="form[element]">
                <div v-if="errors[element]" class="invalid-feedback">
                  {{ $t(`company.errors.${getErrorWord(errors[element][0])}`) }}
                </div>
              </div>

<!--              <div class="mx-modal-form__element">-->
<!--                <label class="form-label">{{ $t(`company.person.form.birthday`) }}</label>-->
<!--                <input type="text" class="form-control" v-mask="'99.99.9999'" v-model="form.birthday" placeholder="TT.MM.JJJJ">-->
<!--                <div v-if="errors['birthday']" class="invalid-feedback">-->
<!--                  {{ $t(`company.errors.${getErrorWord(errors['birthday'][0])}`) }}-->
<!--                </div>-->
<!--              </div>-->

              <div class="mx-modal-form__element">
                <label class="form-label">{{ $t(`company.person.form.gender`) }}</label>
                <b-form-select v-model="form.gender" :options="genders" class="form-control"></b-form-select>
              </div>

              <div class="mx-modal-form__element" style="margin-top: 20px;">
                <label class="mx-modal-form-switch">
                  <input type="checkbox" class="mx-modal-form-switch__input" v-model="form.put_through">
                  <span class="mx-modal-form-switch__slider mx-round"></span>
                </label>
                <label class="form-label">{{ $t(`company.person.form.put_through`) }}</label>
              </div>

            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft
} from "phosphor-vue";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft},
  data() {
    return {
      button_disabled: false,
      preloader: true,
      company: {
        'id': null,
        'name': ''
      },
      form_elements: [
        'position',
        'first_name',
        'last_name',
        'phone',
        'email'
      ],
      genders: [
        { value: null, text: this.$t('company.person.form.gender_select') },
        { value: 'female', text: this.$t('user.gender.female') },
        { value: 'male', text: this.$t('user.gender.male') },
        { value: 'divers', text: this.$t('user.gender.divers') },
        { value: 'indefinite', text: this.$t('user.gender.indefinite') },
      ],
      form: {
        id: null,
        first_name: '',
        last_name: '',
        phone: '',
        birthday: '',
        email: '',
        position: '',
        gender: null,
        put_through: 0,
      },
      errors: [],
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$store.dispatch('companies').then(() => {
        const company = this.$store.getters.companies.find(company => company.id === parseInt(this.$route.params.company_id))
        this.company.name = company.company_name
        this.form.company_id = company.id
        this.preloader = false
      })
    },
    save() {
      this.button_disabled = true;
      this.errors = []
      this.form.gender = this.form.gender === null ? 'female' : this.form.gender
      this.$store.dispatch('personCreate', this.form).then(() => {
        this.$router.replace({
          name: 'company.show', params: {id: this.form.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number|valid)\b/g)[0]
    }
  }
}
</script>
